import { useMajors } from "api/major";
import MajorItem from "./MajorItem";
import Header from "./Header";
import { useUtilities } from "helper";
import { useUser } from "hooks/useUser";
import BlockLoader from "common/BlockLoader";

export default function MajorListing() {
  const {
    institutions: { selectedInstitution },
  } = useUtilities();
  const { data } = useUser();
  const { data: major, isLoading: majorsLoading } = useMajors();

  return (
    <>
      <Header applyFilter={() => {}} />
      <div className="mt-10 flex flex-col gap-5 relative">
        {majorsLoading ? (
          <BlockLoader open={majorsLoading} borderColor="#004E99" />
        ) : major.length === 0 ? (
          <p className="text-center">
            No Major available for{" "}
            <span className="text-theme-purple text-xl font-medium">
              {selectedInstitution?.institution_title ||
                data?.institution?.[0]?.institution_title}
            </span>
          </p>
        ) : (
          major
            .filter((_) => !_.is_archived)
            .map((item) => (
              <MajorItem
                key={item._id}
                id={item._id}
                title={item.title}
                active={item.active}
                applications={item.applications}
                views={item.views}
                createdAt={item.createdAt}
                is_deleted={item.is_deleted}
                quota={item.maximum_quota}
                related_categories={item.related_categories}
                cover_image={item.cover_image}
                is_archived={item.is_archived}
                subtitle={item.subtitle}
              />
            ))
        )}
      </div>
    </>
  );
}
