import { axios, useUtilities } from "helper";
import { useUser } from "hooks/useUser";

export async function logout() {
  return axios.post("/users/logout").then((res) => res.data);
}

export async function switchSession({ id, session_id, token }) {
  return fetch(process.env.REACT_APP_SERVER_URL + "users/switch-session", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify({ id, session_id }),
  }).then((res) => res.json());
}

export const useUserInstitute = () => {
  const {
    institutions: { selectedInstitution },
  } = useUtilities();
  const { data: userData } = useUser();
  const institutionId =
    selectedInstitution?._id || userData?.institution?.[0]?._id;
  return institutionId;
};
