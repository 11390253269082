import { FilledButton } from "common/Button";
import { Input } from "common/Input";
import Modal from "common/Modal";
import Select from "common/Select";
import React, { useEffect, useState } from "react";
import { useAddMajor, useMajors } from "api/major";
import Loader from "common/Loader";
import { useUser } from "hooks/useUser";
import { useUtilities, omit } from "helper";
import { Link } from "react-router-dom";
import { AddCircleIcon, ArchieveIcon } from "assets/icons";
import SearchFilter from "common/SearchFilter";

export const categories = [
  {
    label: "Engineering",
    value: "Engineering",
  },
  {
    label: "Business & Economics",
    value: "Business & Economics",
  },
  {
    label: "Science",
    value: "Science",
  },
  {
    label: "Arts",
    value: "Arts",
  },
  {
    label: "Education",
    value: "Education",
  },
  {
    label: "Health",
    value: "Health",
  },
];

const sortOptions = [
  {
    label: "Newest",
    value: "newest",
  },
  {
    label: "Oldest",
    value: "oldest",
  },
];

export default function Header() {
  const [showModal, setShowModal] = useState(false);
  const [category, setCategory] = useState([]);
  const { notification } = useUtilities();
  const [major, setMajor] = useState("");
  const [majorSubtitle, setMajorSubtitle] = useState("");
  const { data } = useUser();
  const { mutateAsync: addMajor, isLoading: isAdding } = useAddMajor();
  const { data: majors, isLoading: majorsLoading } = useMajors();
  const [searchResults, setSearchResults] = useState([]);
  const [search, setSearch] = useState("");
  const [searchQueryResults, setSearchQueryResults] = useState([]);
  const [filters, setFilters] = useState({
    sortby: "oldest",
    categories: [],
  });

  useEffect(() => {
    if (majors.length > 0) {
      setSearchQueryResults(
        majors.filter(
          (item) =>
            item.title.toLowerCase().includes(search.toLowerCase()) &&
            !item.is_archived
        )
      );
    }
  }, [search]);

  useEffect(() => {
    if (majors.length > 0) {
      setSearchResults(
        majors.filter(
          (item) =>
            item.title.toLowerCase().includes(major.toLowerCase()) &&
            !item.is_archived
        )
      );
    }
  }, [major]);

  const handleConfirm = async () => {
    if (!major.trim()) {
      notification({
        message: "Please enter a Title",
        type: "error",
      });
      return;
    }
    if (!majorSubtitle.trim()) {
      notification({
        message: "Please enter a Subtitle",
        type: "error",
      });
      return;
    }
    if (category.length === 0) {
      notification({
        message: "Please select categories for major",
        type: "error",
      });
      return;
    }
    setShowModal(false);
    await addMajor({
      title: major,
      subtitle: majorSubtitle,
      related_categories: category,
      active: true,
      is_major: true,
      institute: data?.institution?.[0]?._id,
    });
    setMajor("");
    setCategory([]);
  };

  const duplicateMajor = (item) => {
    setShowModal(false);
    addMajor({
      ...omit(item, ["_id", "createdAt", "updatedAt", "__v", "is_deleted"]),
      title: `${item.title} (Copy)`,
    });
  };

  return (
    <>
      <Loader open={isAdding} />
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <FilledButton className="px-6" onClick={() => setShowModal(true)}>
            <AddCircleIcon fill={"white"} />
            Add New Major
          </FilledButton>
          <Link to="/majors/archived">
            <FilledButton className="bg-[#A9A9A9] px-6">
              <ArchieveIcon size="18" fill="white" />
              Archive
            </FilledButton>
          </Link>
        </div>
        <SearchFilter
          searchValue={search}
          onChangeSearchValue={(val) => setSearch(val)}
          searchResults={searchQueryResults.map((item) => (
            <Link
              to={`/majors/${item._id}`}
              className="flex items-center justify-between px-4 py-2 border-b border-[#E4E4E4] cursor-pointer hover:bg-[#F7F7F7]"
            >
              <p className="text-base">{item.title}</p>
              <p className="text-xs">
                {item.related_categories.length > 1
                  ? item.related_categories[0] + " +2 others"
                  : item.related_categories[0]}
              </p>
            </Link>
          ))}
          filterData={
            <>
              <Select
                label="Sort by"
                options={sortOptions}
                value={filters.sortby}
                className="shadow-none rounded-none py-1.5"
                onChange={(value) => setFilters({ ...filters, sortby: value })}
              />
              <Select
                label="Choose Categories"
                options={categories}
                value={filters.categories}
                onChange={(value) => {
                  if (filters.categories.includes(value))
                    setFilters({
                      ...filters,
                      categories: filters.categories.filter(
                        (item) => item !== value
                      ),
                    });
                  else {
                    setFilters({
                      ...filters,
                      categories: [value, ...filters.categories],
                    });
                  }
                }}
                multipleSelect
                onAllClear={() => setFilters({ ...filters, categories: [] })}
                className="shadow-none rounded-none py-1.5"
              />
            </>
          }
          onFilter={() => {}}
        />
      </div>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        heading="Add New Major"
        confirmText="Create"
        onConfirm={handleConfirm}
        disableConfirm={!major.trim() || category === ""}
        childrenMaxheight={false}
      >
        <div className="flex flex-col gap-3">
          <div className="relative">
            <Input
              placeholder="Major Name"
              className="shadow-none rounded-xl"
              value={major}
              onChange={(e) => setMajor(e.target.value)}
              onBlur={() => {
                setTimeout(() => setSearchResults([]), 400);
              }}
            />
            {major && searchResults.length > 0 && (
              <div className="absolute top-12 left-0 w-full bg-white shadow rounded-xl z-10 max-h-72 custom-scroll">
                {searchResults.map((item) => (
                  <div
                    className="flex items-center justify-between px-4 py-2 border-b border-[#E4E4E4] cursor-pointer hover:bg-[#F7F7F7]"
                    onClick={() => duplicateMajor(item)}
                  >
                    <p className="text-sm">{item.title}</p>
                    <p className="text-sm">
                      {item.related_categories.length > 1
                        ? item.related_categories[0] + " +2 others"
                        : item.related_categories[0]}
                    </p>
                  </div>
                ))}
              </div>
            )}
          </div>
          <Input
            placeholder="Major subtitle"
            className="shadow-none rounded-xl"
            value={majorSubtitle}
            onChange={(e) => setMajorSubtitle(e.target.value)}
          />
          <Select
            label="Choose Categories"
            options={categories}
            value={category}
            onChange={(value, index) => {
              if (category.includes(value))
                setCategory(category.filter((item) => item !== value));
              else {
                setCategory([...category, value]);
              }
            }}
            multipleSelect
            onAllClear={() => setCategory([])}
          ></Select>
        </div>
      </Modal>
    </>
  );
}
