import {
  KeySquareIcon,
  CrossIcon,
  CircleCloseIcon,
  CircleCheckIcon,
} from "assets/icons";
import { FilledButton, OutlinedButton } from "common/Button";
import SearchFilter from "common/SearchFilter";
import { useState } from "react";
import Modal from "common/Modal";
import {
  InstituteInput,
  InstituteSelect,
} from "section/institutions/add/CustomInput";
import { useGetInstituteStaticData } from "api/institution";
import { useUtilities, validateEmail } from "helper";
import { useBatches, useCreateBatch } from "api/batches";
import Loader from "common/Loader";
import { useUser } from "hooks/useUser";
import BatchesTable from "./BatchesTable";
import { Link } from "react-router-dom";

export default function Keys() {
  const { data: batches } = useBatches();
  const {
    notification,
    institutions: { selectedInstitution },
  } = useUtilities();
  const { data: userData } = useUser();
  const currentInstitute = selectedInstitution?._id
    ? selectedInstitution
    : userData?.institution?.[0];
  const { data } = useGetInstituteStaticData();
  const { mutateAsync: createBatch, isLoading: isCreating } = useCreateBatch();
  const [showModal, setShowModal] = useState(false);
  const [inputValues, setInputValues] = useState({
    title: "",
    level: "",
    endDate: "",
    description: "",
    emails: "",
  });

  const handleInputChange = (key, value) => {
    setInputValues({ ...inputValues, [key]: value });
  };

  const disabledNextButton = () =>
    inputValues.title === "" ||
    inputValues.level === "" ||
    inputValues.endDate === "" ||
    inputValues.emails === "" ||
    inputValues.emails.split(",").filter((email) => validateEmail(email))
      .length === 0 ||
    inputValues.description === "";

  const handleFormSubmit = async () => {
    if (inputValues.title === "")
      return notification({
        type: "error",
        message: "Please enter title",
      });
    if (inputValues.number_of_keys === "")
      return notification({
        type: "error",
        message: "Please enter number of keys",
      });

    if (inputValues.level === "")
      return notification({
        type: "error",
        message: "Please select level",
      });

    if (inputValues.endDate === "")
      return notification({
        type: "error",
        message: "Please select expire date",
      });

    if (new Date(inputValues.endDate).getTime() < new Date().getTime())
      return notification({
        type: "error",
        message: "Expire date must be greater than today",
      });

    setShowModal(false);
    await createBatch({
      ...inputValues,
      institute_id: currentInstitute._id,
      institute_initials: currentInstitute.institution_initials,
      number_of_keys: inputValues.emails.split(",").filter((email) => validateEmail(email)).length,
    });
    setInputValues({
      title: "",
      level: "",
      endDate: "",
      description: "",
      emails: "",
    });
  };

  return (
    <>
      <Loader open={isCreating} />
      <div className="mt-10">
        <div className="flex items-center justify-between">
          <SearchFilter options={false} />
          <div className="flex items-center text-[#333333] text-[17px] gap-12">
            <p>
              Showing {batches.length} of {batches.length} results
            </p>
            <FilledButton
              className="px-6 py-1.5 rounded-2xl"
              onClick={() => setShowModal(true)}
            >
              <KeySquareIcon />
              Create keys
            </FilledButton>
          </div>
        </div>
        <BatchesTable />
      </div>
      <Modal
        open={showModal}
        showFooter={false}
        showCrossIcon={false}
        modalClass="max-w-2xl"
      >
        <div className="flex items-center justify-between pl-5 pr-10 mb-6">
          <h2 className="text-2xl font-medium">Create Batch</h2>
          <div className="cursor-pointer" onClick={() => setShowModal(false)}>
            <CrossIcon fill="#292D32" width={13} height={13} />
          </div>
        </div>
        <div className="flex gap-4">
          <div className="flex-[0.6] flex flex-col items-center justify-center">
            <div className="flex items-center justify-center flex-col gap-4">
              <InstituteInput
                className={
                  "placeholder:text-[#818181] placeholder:text-sm placeholder:font-light max-w-[190px] min-w-[190px]"
                }
                label="Title"
                placeholder="Title"
                value={inputValues.title}
                onChange={(e) => handleInputChange("title", e.target.value)}
                required
              />
              <InstituteSelect
                className={
                  "placeholder:text-[#818181] placeholder:text-sm placeholder:font-light max-w-[190px] min-w-[190px]"
                }
                label="Level"
                options={Object.keys(data?.subscription_type ?? {}).map(
                  (key) => ({
                    label: "Level " + data?.subscription_type[key],
                    value: key,
                  })
                )}
                tittle="Select Level"
                value={inputValues.level}
                onChange={(value) => handleInputChange("level", value)}
                required
              />
              <InstituteInput
                label="Expire Date"
                type="date"
                className={
                  "placeholder:text-[#818181] placeholder:text-sm placeholder:font-light max-w-[190px] min-w-[190px]"
                }
                placeholder="Expire Date"
                value={inputValues.endDate}
                onChange={(e) => handleInputChange("endDate", e.target.value)}
              />
            </div>
          </div>
          <div className="flex-1">
            <InstituteInput
              className={
                "placeholder:text-[#818181] placeholder:text-sm placeholder:font-light max-w-none w-full min-w-[190px]"
              }
              label="Description"
              placeholder="Description"
              value={inputValues.description}
              onChange={(e) => handleInputChange("description", e.target.value)}
              type="textarea"
              rows={4}
            />
            <InstituteInput
              className={
                "placeholder:text-[#818181] placeholder:text-sm placeholder:font-light max-w-none w-full min-w-[190px]"
              }
              label="Emails to assign"
              placeholder="Paste or type up to 1,000 emails here"
              value={inputValues.emails}
              onChange={(e) => handleInputChange("emails", e.target.value)}
              type="emaillist"
              required
            />
          </div>
        </div>
        <div className="flex items-center justify-center gap-7 mt-7">
          <OutlinedButton
            className="rounded-md borders-[#A9A9A9] text-[#A9A9A9] py-3"
            onClick={() => setShowModal(false)}
          >
            <CircleCloseIcon color="#A9A9A9" />
            Cancel
          </OutlinedButton>
          <FilledButton
            className={"rounded-md bg-[#F29423]"}
            disabled={disabledNextButton()}
            onClick={handleFormSubmit}
          >
            <CircleCheckIcon color="white" />
            Confirm{" "}
            {inputValues.emails
              ? `(${
                  inputValues.emails
                    .split(",")
                    .filter((email) => validateEmail(email)).length
                } keys)`
              : ""}
          </FilledButton>
        </div>
        <p className="mt-4 text-[9px] text-center">
          By creating a batch of keys, you acknowledge and agree to our{" "}
          <Link className="underline decoration-theme-blue text-theme-blue font-medium">
            Terms of Use
          </Link>{" "}
          and{" "}
          <Link className="underline decoration-theme-blue text-theme-blue font-medium">
            Privacy Policy.
          </Link>
        </p>
      </Modal>
    </>
  );
}
