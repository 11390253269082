import { useKeysByBatch } from "api/batches";
import {
  DownChevronFilled,
  CircleIcon,
  ChevronLeftIcon,
  UpSideDownIcon,
} from "assets/icons";
import BlockLoader from "common/BlockLoader";
import { FilledButton, OutlinedButton } from "common/Button";
import Label from "common/Label";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { KeyStatusStyle } from "../enum";
import { StatusButton } from "common/StatusButton";

export default function KeysTable() {
  const { batchId } = useParams();
  const { data, isLoading } = useKeysByBatch(batchId);
  const [selectRows, setSelectRows] = useState([]);
  const handleSelectRow = (id) => {
    setSelectRows((prev) => {
      if (prev.includes(id)) return prev.filter((item) => item !== id);
      return [...prev, id];
    });
  };

  if (isLoading)
    return (
      <div className="relative mt-10">
        <BlockLoader open={isLoading} borderColor="#004E99" />
      </div>
    );

  return (
    <>
      <div>
        <div className="mt-6">
          <table className="w-full">
            <thead className="bg-[#F2F2F2] ">
              <tr>
                <th className="py-2 px-6 rounded-tl-3xl rounded-bl-3xl">
                  <input
                    type="checkbox"
                    className="bg-transparent cursor-pointer"
                    checked={data.every((item) =>
                      selectRows.includes(item._id)
                    )}
                    onChange={() => {
                      if (data.every((item) => selectRows.includes(item._id))) {
                        setSelectRows([]);
                      } else {
                        setSelectRows(data.map((item) => item._id));
                      }
                    }}
                  />
                </th>
                <th className="py-2 text-left text-[#595959] font-normal text-lg">
                  ID No.
                </th>
                <th className="py-2 text-center text-[#595959] font-normal text-lg">
                  Email assigned
                </th>
                <th className="py-2 text-center text-[#595959] font-normal text-lg">
                  <div className="flex items-center gap-1.5">
                    <UpSideDownIcon />
                    Status
                  </div>
                </th>
                <th className="py-2 text-center text-[#595959] font-normal text-lg">
                  First activate
                </th>
                <th className="py-2 text-center text-[#595959] font-normal text-lg">
                  User type
                </th>
                <th className="py-2 text-center text-[#595959] font-normal text-lg rounded-tr-3xl rounded-br-3xl">
                  <div className="flex items-center gap-1.5">
                    <UpSideDownIcon />
                    Applied?
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="">
              {data?.map((item) => {
                return (
                  <tr className="">
                    <th className="py-4 px-6">
                      <input
                        type="checkbox"
                        checked={selectRows.includes(item._id)}
                        onChange={() => handleSelectRow(item._id)}
                        className="cursor-pointer"
                      />
                    </th>
                    <td
                      className="py-4 text-left text-[#828282] cursor-pointer"
                      title="Click to copy"
                      onClick={() => navigator.clipboard.writeText(item?._id)}
                    >
                      {item?._id.slice(-6) || "-"}
                    </td>
                    <td className="py-4 text-center">{item?.email || "-"}</td>
                    <td className="py-4 text-center">
                      <StatusButton
                        status={item?.status}
                        style={KeyStatusStyle[item.status]}
                      />
                    </td>
                    <td className="py-4 text-[#828282] text-center">
                      {item.first_active
                        ? new Date(item.first_active).toLocaleDateString()
                        : "-"}
                    </td>
                    <td className="py-4 text-[#828282 text-center">
                      {item.user_type ? item.user_type : "-"}
                    </td>
                    <td className="py-4 text-[#828282] text-center">
                      {item.applied || "No"}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {data.length > 20 && (
            <div className="flex items-center justify-center gap-7 mt-10">
              <ChevronLeftIcon width="8" height="16" className="rotate-180" />
              <p className="text-[22px] text-[#333333]">1</p>
              <p className="text-[22px] text-[#333333]">2</p>
              <p className="text-2xl font-medium text-[#006ED8]">3</p>
              <p className="text-[22px] text-[#333333]">4</p>
              <p className="text-[22px] text-[#333333]">5</p>
              <ChevronLeftIcon width="8" height="16" />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
