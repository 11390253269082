import { axios } from "helper";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useUtilities } from "helper";
import { useEffect } from "react";
import { addBatch, setBatches } from "redux/reducers/batches";
import { useUserInstitute } from "./user";
import { setBatcheDetailsAction } from "redux/reducers/batchDetails";

export async function createBatch(data) {
  return await axios
    .post("batches", data)
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useCreateBatch() {
  const { notification, dispatch } = useUtilities();
  return useMutation(createBatch, {
    onSuccess: (data) => {
      if (data.statusCode === 201) {
        dispatch(addBatch(data.data));
        notification({
          message: "Batch added successfully",
          type: "success",
        });
      } else {
        notification({
          message: "Failed to add Batch",
          type: "error",
        });
      }
    },
    onError: (error) => {
      notification({
        message: "Failed to add Batch",
        type: "error",
      });
    },
  });
}

export async function getBatches(instituteId) {
  return axios
    .get("batches", {
      params: {
        instituteId,
      },
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

function useGetBatches(instituteId) {
  return useQuery(["batches", instituteId], () => getBatches(instituteId), {
    enabled: !!instituteId,
  });
}

export function useBatches() {
  const institutionId = useUserInstitute();
  const { data, isLoading, isError, error } = useGetBatches(institutionId);
  const {
    dispatch,
    batches: {
      batches,
      institution,
      loaded,
      hasNextPage: nextPageExist,
      totalDocs: total,
      totalPages: pages,
    },
  } = useUtilities();

  useEffect(() => {
    if (data && institution !== institutionId) {
      const { limit, nextPage, hasNextPage, totalDocs, totalPages, results } =
        data?.data;
      dispatch(
        setBatches({
          institution: institutionId,
          batches: results,
          limit,
          nextPage,
          hasNextPage,
          totalDocs,
          totalPages,
        })
      );
    }
  }, [data]);

  return {
    data: batches,
    isLoading: isLoading || !loaded || institution !== institutionId,
    isError,
    error,
    hasNextPage: nextPageExist,
    total,
    pages,
  };
}

export async function getKeysByBatch(data) {
  return axios
    .get(`keys`, {
      params: {
        batchId: data.id,
        limit: 10,
        page: data.currentPage,
      },
    })
    .then((res) => res.data)
    .catch((err) => new Error(err));
}

export function useGetKeysByBatch(data) {
  return useQuery(["keysByBatch", data.id], () => getKeysByBatch(data), {
    enabled: !!data.id,
  });
}

export function useKeysByBatch(id, currentPage = 1) {
  const { data, isLoading, isError, error } = useGetKeysByBatch({
    id,
    currentPage,
  });
  const {
    dispatch,
    batchDetails: {
      batchDetails,
      batch,
      loaded,
      hasNextPage: haveMore,
      totalDocs: pages,
    },
  } = useUtilities();
  useEffect(() => {
    if (data && batch !== id) {
      const {
        limit,
        nextPage,
        hasNextPage,
        totalDocs,
        totalPages,
        results,
        currentPage,
      } = data?.data;
      dispatch(
        setBatcheDetailsAction({
          batch: id,
          batchDetails: results,
          limit,
          nextPage,
          hasNextPage,
          totalDocs,
          totalPages,
          currentPage,
        })
      );
    }
  });
  return {
    data: batchDetails || [],
    isLoading: isLoading || !loaded || batch !== id,
    isError,
    error,
    hasNextPage: haveMore,
    totalPages: pages,
  };
}
