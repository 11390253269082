import { KeySquareIcon, CrossIcon } from "assets/icons";
import { FilledButton } from "common/Button";
import SearchFilter from "common/SearchFilter";
import { useState } from "react";
import Modal from "common/Modal";
import {
  InstituteInput,
  InstituteSelect,
} from "section/institutions/add/CustomInput";
import { useGetInstituteStaticData } from "api/institution";
import { useUtilities } from "helper";
import { useBatches, useCreateBatch, useKeysByBatch } from "api/batches";
import Loader from "common/Loader";
import { useUser } from "hooks/useUser";
import KeysTable from "./KeysTable";
import { useParams } from "react-router-dom";
import BlockLoader from "common/BlockLoader";
import BreadCrumbs from "common/BreadCrumbs";
import Details from "./Details";

export default function KeyDetails() {
  const { batchId } = useParams();
  const { data, isLoading, totalPages } = useKeysByBatch(batchId);
  const {
    notification,
    institutions: { selectedInstitution },
  } = useUtilities();
  const { data: userData } = useUser();
  const currentInstitute = selectedInstitution?._id
    ? selectedInstitution
    : userData?.institution?.[0];
  const { mutateAsync: createBatch, isLoading: isCreating } = useCreateBatch();
  const [showModal, setShowModal] = useState(false);
  const [inputValues, setInputValues] = useState({
    title: "",
    number_of_keys: 0,
    level: "",
    endDate: "",
    description: "",
    emails: "",
  });

  const handleInputChange = (key, value) => {
    setInputValues({ ...inputValues, [key]: value });
  };

  const disabledNextButton = () =>
    inputValues.title === "" ||
    inputValues.number_of_keys === "" ||
    inputValues.level === "" ||
    inputValues.endDate === "";

  const handleFormSubmit = async () => {
    if (inputValues.title === "")
      return notification({
        type: "error",
        message: "Please enter title",
      });
    if (inputValues.number_of_keys === "")
      return notification({
        type: "error",
        message: "Please enter number of keys",
      });

    if (inputValues.level === "")
      return notification({
        type: "error",
        message: "Please select level",
      });

    if (inputValues.endDate === "")
      return notification({
        type: "error",
        message: "Please select expire date",
      });

    if (new Date(inputValues.endDate).getTime() < new Date().getTime())
      return notification({
        type: "error",
        message: "Expire date must be greater than today",
      });

    setShowModal(false);
    await createBatch({
      ...inputValues,
      institute_id: currentInstitute._id,
      institute_initials: currentInstitute.institution_initials,
    });
    setInputValues({
      title: "",
      number_of_keys: "",
      level: "",
      endDate: "",
    });
  };

  if (isLoading) return <BlockLoader borderColor="#004E99" />;

  return (
    <>
      <Loader open={isCreating} />
      {Object.keys(data?.[0]?.batch).length !== 0 ? (
        <BreadCrumbs
          data={[
            {
              label: "keys",
              link: "/keys",
              style: { color: "#BBC4FF" },
            },
            {
              label: data?.[0]?.batch?.batch_id,
              link: `/keys/${batchId}`,
            },
          ]}
        />
      ) : (
        ""
      )}
      <div className="flex gap-5">
        <div className="flex-[2]">
          <div className="mt-10">
            <div className="flex items-center justify-between">
              <SearchFilter options={false} />
              <div className="flex items-center text-[#333333] text-[17px] gap-12">
                <p>
                  Showing {data.length} of {totalPages} results
                </p>
                <FilledButton
                  className="px-6 py-1.5 rounded-2xl"
                  onClick={() => setShowModal(true)}
                >
                  <KeySquareIcon />
                  Add keys
                </FilledButton>
              </div>
            </div>
            <KeysTable />
          </div>
        </div>
        <div className="flex-1">
          <Details />
        </div>
      </div>
      <Modal
        open={showModal}
        showFooter={false}
        showCrossIcon={false}
        modalClass="max-w-2xl"
      >
        <div className="flex items-center justify-between pl-5 pr-10 mb-6">
          <h2 className="text-2xl font-medium">Create Batch</h2>
          <div className="cursor-pointer" onClick={() => setShowModal(false)}>
            <CrossIcon fill="#292D32" width={13} height={13} />
          </div>
        </div>
        <div className="flex gap-4">
          <div className="flex-[0.6] flex flex-col items-center justify-center">
            <div className="flex items-center justify-center flex-col gap-4">
              <InstituteInput
                className={
                  "placeholder:text-[#818181] placeholder:text-sm placeholder:font-light max-w-[190px] min-w-[190px]"
                }
                label="Title"
                placeholder="Title"
                value={inputValues.title}
                onChange={(e) => handleInputChange("title", e.target.value)}
                required
              />
              <InstituteSelect
                className={
                  "placeholder:text-[#818181] placeholder:text-sm placeholder:font-light max-w-[190px] min-w-[190px]"
                }
                label="Level"
                options={Object.keys(data?.subscription_type ?? {}).map(
                  (key) => ({
                    label: "Level " + data?.subscription_type[key],
                    value: key,
                  })
                )}
                tittle="Select Level"
                value={inputValues.level}
                onChange={(value) => handleInputChange("level", value)}
                required
              />
              <InstituteInput
                label="Expire Date"
                type="date"
                className={
                  "placeholder:text-[#818181] placeholder:text-sm placeholder:font-light max-w-[190px] min-w-[190px]"
                }
                placeholder="Expire Date"
                value={inputValues.endDate}
                onChange={(e) => handleInputChange("endDate", e.target.value)}
              />
            </div>
            {/* <div className="mt-12">
              <FilledButton
                className={`rounded-full ${
                  disabledNextButton() ? "opacity-30" : "bg-theme-yellow"
                }`}
                onClick={handleFormSubmit}
              >
                Create
              </FilledButton>
            </div> */}
          </div>
          <div className="flex-1">
            {/* <h2 className="text-2xl font-medium">Batch Creation & Policy</h2>
            <p className="text-sm max-w-[340px] text-justify">
              By creating a batch of keys, you acknowledge and agree to
              our&nbsp;
              <Link className="text-[#0158FF] underline font-bold">
                Terms of Use
              </Link>
              &nbsp; and&nbsp;
              <Link className="text-[#0158FF] underline font-bold">
                Privacy Policy
              </Link>
              . Please note that while you can modify the batch at any time,
              deletion is not possible. Initially, your batch will be marked as
              'Draft.' Once you assign the keys to specific users, the status of
              the batch will change to 'Assigned.' Additionally, be aware that
              all batches have expiration dates (counting down starting from
              today), after which they will no longer be valid but could be
              extended.
            </p> */}
            <InstituteInput
              className={
                "placeholder:text-[#818181] placeholder:text-sm placeholder:font-light max-w-none w-full min-w-[190px]"
              }
              label="Description"
              placeholder="Description"
              value={inputValues.description}
              onChange={(e) => handleInputChange("description", e.target.value)}
              type="textarea"
              rows={4}
            />
            <InstituteInput
              className={
                "placeholder:text-[#818181] placeholder:text-sm placeholder:font-light max-w-none w-full min-w-[190px]"
              }
              label="Emails to assign"
              placeholder="Paste or type up to 1,000 emails here"
              value={inputValues.emails}
              onChange={(e) => handleInputChange("emails", e.target.value)}
              type="emaillist"
              required
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
