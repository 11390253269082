import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useUserAuth } from "context/authProvider";
import Loader from "common/Loader";
import OutlinedButton from "common/Button/OutlinedButton";
import { Input } from "common/Input";
import { UserTypes } from "constants/user";
import Modal from "common/Modal";
import { switchSession } from "api/user";

const Login = () => {
  const { setUser } = useUserAuth();
  const { state } = useLocation();
  const [alreadLoggedIn, setAlreadyLoggedIn] = React.useState({});
  const [inputVales, setInputValues] = React.useState({
    email: "",
    password: "",
    error: "",
    loading: false,
  });

  function handleSubmit() {
    setInputValues({ ...inputVales, loading: true });
    fetch(process.env.REACT_APP_SERVER_URL + "users/admin-login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: inputVales.email,
        password: inputVales.password,
        platform: navigator?.userAgentData?.platform || "",
        language: navigator?.language || "",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.statusCode === 200) {
          if (
            data.user.user_type === UserTypes.VIEWER ||
            data.user.institution.length === 0 &&
            data.user.user_type !== UserTypes.SADMIN
          ) {
            setInputValues({
              ...inputVales,
              error: "You are not authorized to access this portal.",
              loading: false,
            });
            return;
          }
          setInputValues({ ...inputVales, error: "", loading: false });
          if (data.user.loggedIn) {
            setAlreadyLoggedIn(data.user);
            return;
          }
          setUser(data.user.token);
          localStorage.setItem("token", data.user.token);
          localStorage.setItem("session", JSON.stringify(data.user.session_id));
        } else {
          setInputValues({
            ...inputVales,
            error: data.message,
            loading: false,
          });
        }
      })
      .catch(() => {
        setInputValues({
          ...inputVales,
          error: "Something went wrong!",
          loading: false,
        });
      });
  }

  return (
    <>
      <Modal
        open={alreadLoggedIn?.loggedIn}
        onClose={() => setAlreadyLoggedIn({})}
        confirmText="Continue"
        heading="Already Logged In"
        onConfirm={() => {
          setInputValues({ ...inputVales, loading: true });
          switchSession({
            id: alreadLoggedIn?._id,
            session_id: alreadLoggedIn?.session_id,
            token: alreadLoggedIn?.token,
          }).then((res) => {
            setUser(alreadLoggedIn.token);
            localStorage.setItem("token", alreadLoggedIn.token);
            localStorage.setItem("session", JSON.stringify(alreadLoggedIn.session_id));
            setInputValues({ ...inputVales, loading: false });
          });
        }}
      >
        <p>
          You are already logged in from another device. Do you want to continue
          here?
        </p>
      </Modal>
      <Loader open={inputVales.loading} />
      <div className="flex flex-col max-w-sm w-full">
        <div className="mb-4">
          <Input
            id="email"
            type="text"
            placeholder="Email"
            value={inputVales.email}
            onChange={(e) =>
              setInputValues({ ...inputVales, email: e.target.value })
            }
          />
        </div>
        <div className="">
          <Input
            id="password"
            type="password"
            placeholder="Password"
            value={inputVales.password}
            onChange={(e) =>
              setInputValues({ ...inputVales, password: e.target.value })
            }
          />
          <Link
            className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800 text-right w-full"
            to="/user-auth/forget-password"
          >
            Forgot Password?
          </Link>
          {inputVales.error && (
            <p className="text-red-500 text-xs italic">
              {inputVales.error ?? "Something went wrong!"}
            </p>
          )}
          {state?.success && (
            <p className="text-green-500 text-xs italic">{state?.success}</p>
          )}
        </div>
        <OutlinedButton
          onClick={() => {
            handleSubmit();
          }}
          className={"w-max mx-auto mt-6"}
        >
          Log In
        </OutlinedButton>
      </div>
    </>
  );
};

export default Login;
