import { axios } from "helper";
import { useUserAuth } from "context/authProvider";
import { useEffect, useState } from "react";
import { UserTypes } from "constants/user";
export function useUser() {
  const { user, setUser } = useUserAuth();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!user || userData) {
      setLoading(false);
      return;
    }
    axios
      .get("/users/get-profile/" + localStorage.getItem("session"))
      .then((res) => {
        if (
          res.data.user_type === UserTypes.VIEWER ||
          (res.data.institution.length === 0 &&
            res.data.user_type !== UserTypes.SADMIN)
        ) {
          localStorage.removeItem("token");
          setUser(null);
          setLoading(false);
          return;
        }
        setUserData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        localStorage.removeItem("token");
        setUser(null);
        setLoading(false);
      });
  }, [user]);
  return { data: userData, isLoading: loading };
}
