import {
  DashboardIcon,
  MajorsIcon,
  KeysIcon,
  UsersIcon,
  ReportsIcon,
  InsightsIcon,
  InstitutionsIcon,
} from "assets/icons";
import ChevronLeftIcon from "assets/icons/ChevronLeftIcon";
import OutsideClickHandler from "common/OutsideClickHandler";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useUser } from "hooks/useUser";
import { useUserAuth } from "context/authProvider";
import { useSocket } from "context/socket";
import { logout } from "api/user";
import Modal from "common/Modal";
import { useUtilities } from "helper";
import { UserTypes } from "constants/user";

const menuItems = [
  {
    title: "Dashboard",
    icon: <DashboardIcon />,
  },
  { title: "Majors", icon: <MajorsIcon /> },
  {
    title: "Keys",
    icon: <KeysIcon />,
  },
  {
    title: "Users",
    icon: <UsersIcon />,
  },
  {
    title: "Reports",
    icon: <ReportsIcon />,
  },
  {
    title: "Insights",
    icon: <InsightsIcon />,
  },
  {
    title: "Institutions",
    icon: <InstitutionsIcon />,
  },
];

function Menu({ menuFullWidth, data }) {
  const location = useLocation();
  const activeMenu = location.pathname.split("/")[1]
    ? location.pathname.split("/")[1]
    : "dashboard";

  return (
    <div className="flex flex-col gap-y-1">
      {menuItems.map((item) => {
        if (
          item.title.toLowerCase() === "institutions" &&
          data?.user_type !== UserTypes.SADMIN
        )
          return;
        return (
          <Link
            className={`flex overflow-hidden items-center space-x-4 px-6 py-2 cursor-pointer hover:bg-[#F1F3FD] border-l-4 transition-all duration-1000 ease-in-out h-10 ${
              activeMenu === item.title.toLowerCase()
                ? "border-l-theme-blue bg-[#F1F3FD]"
                : "border-l-transparent"
            }`}
            to={`/${item.title.toLowerCase()}`}
          >
            <div className="">{item.icon}</div>
            <div
              className={`text-theme-blue transition-all duration-1000 ease-in-out ${
                menuFullWidth ? "block" : "hidden"
              }`}
            >
              {item.title}
            </div>
          </Link>
        );
      })}
    </div>
  );
}

function UserInfo({ menuFullWidth, data }) {
  const [showUserMenu, setShowUserMenu] = useState(false);
  const { setUser } = useUserAuth();
  return (
    <>
      <div className="relative mx-3">
        <div
          className={`transition-all duration-500 ease-in-out flex items-center gap-2 overflow-hidden ${
            menuFullWidth ? "justify-start " : "justify-center"
          }`}
        >
          {data?.avatar ? (
            <img
              src={data?.avatar}
              alt="user"
              className="w-12 h-12 rounded-full border-2 border-theme-blue"
            />
          ) : (
            <div className="min-w-[48px] uppercase flex items-center text-theme-blue text-2xl justify-center w-12 h-12 rounded-full bg-[#F1F3FD] border-2 border-theme-blue">
              {data?.first_name?.[0]}
            </div>
          )}
          {menuFullWidth ? (
            <>
              <div className="h-12 bg-[#F1F3FD] text-theme-blue flex items-center justify-center w-full rounded-md">
                {data?.first_name}
              </div>
              <div
                className="h-12 bg-[#F1F3FD] text-theme-blue flex items-center justify-center px-4 rounded-md cursor-pointer"
                onClick={() => setShowUserMenu(!showUserMenu)}
              >
                <ChevronLeftIcon
                  className={`transition-all duration-500 ease-in-out ${
                    showUserMenu ? "rotate-90" : "-rotate-90"
                  }`}
                />
              </div>
            </>
          ) : (
            ""
          )}
        </div>
        {menuFullWidth && showUserMenu ? (
          <OutsideClickHandler
            onClickOutside={() => setShowUserMenu(false)}
            className="bg-white absolute bottom-full w-full shadow rounded-md right-0 mb-2"
          >
            <div className="my-3 flex flex-col w-full gap-1">
              <h2 className="px-3 py-1 hover:bg-[#F1F3FD] cursor-pointer text-theme-blue">
                My account
              </h2>
              <h2 className="px-3 py-1 hover:bg-[#F1F3FD] cursor-pointer text-theme-blue">
                Setting
              </h2>
              <h2
                className="px-3 py-1 hover:bg-[#F1F3FD] cursor-pointer text-theme-blue"
                onClick={async () => {
                  await logout();
                  setUser(null);
                  localStorage.clear();
                }}
              >
                Logout
              </h2>
            </div>
          </OutsideClickHandler>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default function LeftPanel({ menuFullWidth }) {
  const { data } = useUser();
  const { setUser } = useUserAuth();
  const { socket } = useSocket();
  const [showSessionModal, setShowSessionModal] = useState(false);

  useEffect(() => {
    if (socket && data?.session_id) {
      socket.emit("join-room", { room: data?.session_id });
      // socket.on("joined-room", (data) => {});
      socket.on("switch-session", ({ session_id }) => {
        if (session_id === data?.session_id) return;
        setShowSessionModal(true);
      });
    }
  }, [socket, data?.session_id]);

  const handleConfirm = () => {
    setShowSessionModal(false);
    setUser(null);
    localStorage.clear();
    window.location.reload();
  };

  const {
    institutions: { selectedInstitution },
  } = useUtilities();

  return (
    <>
      <Modal
        open={showSessionModal}
        onClose={handleConfirm}
        showCancel={false}
        heading="Session Overtaken"
        confirmText="Ok"
        onConfirm={handleConfirm}
      >
        <p className="text-lg">
          Your account has been logged in from another device.
        </p>
      </Modal>
      <div className=" flex flex-col h-full ">
        <div
          className={`pl-4 flex ${
            menuFullWidth ? "flex-row" : "flex-col"
          } items-center gap-2`}
        >
          <Link to="/" className="overflow-hidden flex gap-2 items-center">
            <svg
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.3414 10.3412C8.24758 12.4338 6.83813 15.1127 6.29939 18.0235C5.76065 20.9343 6.118 23.9402 7.32419 26.6436L7.65653 27.345L9.92846 25.0731L9.77431 24.7294C8.86048 22.2519 8.82064 19.5365 9.66139 17.0332C10.5021 14.5299 12.173 12.3891 14.3971 10.9654C16.6212 9.5418 19.265 8.92086 21.8903 9.20552C24.5156 9.49019 26.9648 10.6634 28.8321 12.5306C30.6993 14.3979 31.8725 16.8471 32.1571 19.4724C32.4418 22.0977 31.8209 24.7415 30.3972 26.9656C28.9736 29.1896 26.8328 30.8605 24.3295 31.7013C21.8262 32.542 19.1108 32.5022 16.6332 31.5884L16.2896 31.4342L14.0177 33.7061L14.7135 34.0441C17.1475 35.132 19.8326 35.534 22.4785 35.2068C25.1245 34.8795 27.6307 33.8354 29.7263 32.1872C31.8219 30.539 33.4273 28.3495 34.3689 25.8552C35.3106 23.3609 35.5527 20.6567 35.0691 18.0348C34.5854 15.413 33.3945 12.9731 31.625 10.9789C29.8554 8.98468 27.5746 7.51197 25.0288 6.71991C22.4831 5.92784 19.7693 5.84653 17.1807 6.48477C14.5921 7.12301 12.2272 8.45652 10.3414 10.3412Z"
                fill="#F29423"
              />
              <path
                d="M11.402 32.0846L20.8334 22.6532L20.8878 27.0436L23.8577 27.0436L23.8647 17.5005H14.3188L14.2622 20.5269L18.7121 20.5319L9.28067 29.9633L11.402 32.0846Z"
                fill="#F29423"
              />
            </svg>
            <span
              className={`font-medium text-theme-blue text-2xl ${
                menuFullWidth ? "contents" : "hidden"
              }`}
            >
              nexme
            </span>
          </Link>
          <div className="mt-1 flex items-center justify-center gap-1">
            <img
              src={
                selectedInstitution?.profile_image
                  ? selectedInstitution?.profile_image
                  : data?.institution?.[0]?.profile_image
              }
              className="w-7 h-7 rounded-full object-contain"
            />
            <h2
              className={`font-semibold text-theme-orange text-xl ${
                menuFullWidth ? "contents" : "hidden"
              }
            `}
            >
              {selectedInstitution?.institution_initials
                ? selectedInstitution?.institution_initials
                : data?.institution?.[0]?.institution_initials}
            </h2>
          </div>
        </div>
        <div className="mt-14">
          <Menu menuFullWidth={menuFullWidth} data={data} />
        </div>
        <div className="mt-auto">
          <UserInfo menuFullWidth={menuFullWidth} data={data} />
        </div>
      </div>
    </>
  );
}
